import React from 'react';
import './Home.css';
import './App.js';

function Home({ setCurrentPage }) {
  return (
    <div className="main">
        <video autoPlay loop muted playsInline>
          <source src="/videos/bg10.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="home-container">
        <div className="t1"><span className="light-grey-text">I’m Will,</span> who loves web app design &amp; crafting unique user experiences.</div>
        <div className="p1">Your idea deserves excellence. Book a call today.</div>
        <div className="buttons">
            <button className="secondary-btn" onClick={() => setCurrentPage('services')}>Our Services</button>
            <button className="primary-btn" onClick={() => window.location.href = 'https://cal.com/athca/30min?user=athca'}>Book a call</button>
        </div>
        <div className="recent-works-container">
            <div className="recent-works-txt">Recent works</div>
            <img src="./assets/arrow.png" className="arrow" alt="arrow" />
        </div>
        </div>
    </div>
  );
}

export default Home;
